//
// Harbor Bay 1.0.0
// Built by Penso Co.
// https://penso.co
//

// Before we include Bootstrap, begin by setting some overrides.
//
@import './_partials/_colors.scss',
        './_partials/_fonts.scss',
        './_partials/_vars.scss';

$theme-colors: (
  primary: $blue,
) !default;

// Options
//
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: false !default;
$enable-print-styles: true !default;

// Grid containers
//
$container-max-widths: (
  sm: 540px,
  md: 640px,
  lg: 1000px,
  xl: 1200px,
) !default;

$grid-gutter-width: 40px !default;

// Body
//
$body-bg: $white !default;
$body-color: $blue !default;

// Links
//
$link-decoration: none !default;
$link-hover-decoration: none !default;

// Font, line-height, and color for body text, headings, and more.
//$font-family-sans-serif: $helvetica-text-light !default;
//$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;
$font-size-xs: 0.75rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: 1.8rem !default;
$h2-font-size: 1.4rem !default;
$h3-font-size: 1.2rem !default;
$h4-font-size: 1.1rem !default;
$h5-font-size: 1.05rem !default;
$h6-font-size: 1rem !default;

//$headings-font-family: $circularpro-bd !default;
$headings-font-weight: 100 !default;
$headings-line-height: 1.5 !default;
$headings-color: inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

// Bootstrap imports
//
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/code';
@import '../../node_modules/bootstrap/scss/grid';
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import '../../node_modules/bootstrap/scss/transitions';
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
//@import "../../node_modules/bootstrap/scss/card";
//@import "../../node_modules/bootstrap/scss/breadcrumb";
//@import "../../node_modules/bootstrap/scss/pagination";
//@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
//@import "../../node_modules/bootstrap/scss/alert";
//@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
//@import "../../node_modules/bootstrap/scss/close";
//@import "../../node_modules/bootstrap/scss/modal";
//@import "../../node_modules/bootstrap/scss/tooltip";
//@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import '../../node_modules/bootstrap/scss/utilities';
@import "../../node_modules/bootstrap/scss/print";

// local imports
@import './_partials/_typography.scss';
@import './_partials/_buttons.scss';
@import './_partials/_header.scss';
@import './_partials/_navigation.scss';
@import './_partials/_footer.scss';
@import './_partials/_content.scss';
