//
// Header
//

#banner {
  background-color: $primary;
  color: $white;

  padding-bottom: $gutter/3;
  padding-top: $gutter/3;
  text-align: center;
  text-transform: uppercase;

  p {
    @extend .lars-bold;
    @include font-size(10px);
    letter-spacing: 1.08px;
    line-height: 1;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(md) {
      @include font-size(14px);
      letter-spacing: 1.8px;
    }
  }

  a {
    color: $white;
    text-decoration: underline;

    &:after {
      border: solid $white;
      border-width: 0 1px 1px 0;
      content: '';
      display: inline-block;
      margin-left: $gutter/5;
      margin-top: -2px;
      padding: 2px;
      position: relative;
      top: -2px;
      transform: rotate(-45deg) translateX(0px);
      -webkit-transform: rotate(-45deg) translateX(0px);
    }

    &:hover {
      text-decoration: none;
    }
  }
}

#header {
  padding: 0 0 $gutter/2;

  .logo {
    height: auto;
    padding: 0;
    margin: 0;
    width: 150px;

    @include media-breakpoint-up(lg) {
      width: 280px;
    }
  }

  .container-fluid {
    padding-top: $gutter;

    @include media-breakpoint-up(md) {
      padding-left: $gutter*1.666;
      padding-right: $gutter*1.666;
      padding-top: $gutter*1.5;
      max-width: 1500px;
    }
  }

  .connected & {
    background-color: $yellow;
  }
}
