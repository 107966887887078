//
// Content
//

// Homepage
//
.homepage-top {

  @include media-breakpoint-down(sm) {
    background: none !important;
  }

  @include media-breakpoint-up(md) {
    background-color: transparent;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 350px 450px;
  }

  @include media-breakpoint-up(lg) {
    background-size: 700px 900px;
  }
}

.intro {
  padding-bottom: 0;

  .connected & {
    background-color: $yellow;
  }
}

.directory {

  .sub {
    margin-top: $gutter;

    @include media-breakpoint-up(md) {
      margin-top: $gutter*3;
    }

    &[data-bg-color="mint"] {
      background-color: $mint;
    }

    &[data-bg-color="yellow"] {
      background-color: $yellow;
    }

    &[data-bg-color="blue"] {
      background-color: $blue;
      color: $white;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0 0 ($gutter);
      @include font-size(16px);
      line-height: 22px;
      width: 100%;

      @include media-breakpoint-up(md) {
        column-count: 3;
        @include font-size(22px);
        line-height: 36px;
      }

      li { padding-bottom: $gutter; }
    }
  }

  hr { border-color: $white; }
}

.lead--in {
  @extend .lars-light;
  @include font-size(18px);
  line-height: 26px;

  @include media-breakpoint-up(md) {
    @include font-size(36px);
    line-height: 48px;
    max-width: 80%;
  }
}

.section--content {
  padding-bottom: $gutter*1.5;
  padding-top: $gutter*1.5;

  @include media-breakpoint-up(md) {
    padding-bottom: $gutter*2.5;
    padding-top: $gutter*2.5;
  }
}

.section--icon {
  height: auto;
  position: relative;
  top: -10px;
  width: 100%;

  &.business { top: 0; }

  @include media-breakpoint-up(md) {
    top: 25px;
    width: 125px;
  }
}

.where-we-work,
.welcome,
.featured--businesses {
  position: relative;
}

.featured--businesses {

  a.featured--link {
    @include font-size(18px);
    line-height: 26px;
    letter-spacing: 0;

    @include media-breakpoint-up(md) {
      @include font-size(36px);
      line-height: 48px;
    }

    img {
      display: inline-block;
      margin-bottom: $gutter;
    }

    .btn--arrow-sm {
      transform: translateX(0px);
      transition: transform .25s linear;
    }

    &:hover {

      .btn--arrow-sm { transform: translateX(3px); }
    }
  }

  .directory {
    border-top: 1px solid $blue;
    position: relative;
    width: 100%;
  }
}

.bg-peach,
.bg-yellow,
.bg-mint {
  background-color: $peach;
  height: 88%;
  left: 0;
  position: absolute;
  top: 0;
  width:100%;
}

.bg-yellow { background-color: $yellow; }

.bg-mint {
  background-color: $mint;
  bottom: 0;
  height: 75%;
  top: auto;
}

.color--block {
  padding: ($gutter*1.5) $gutter;

  ul {
    @include font-size(16px);
    list-style: none;
    margin: $gutter 0;
    padding: 0;

    li {
      margin-bottom: $gutter/2;
    }

    @include media-breakpoint-up(md) {
      @include font-size(22px);
    }
  }

  &.bg--peach {
    background-color: $peach;
  }
}

.bg--yellow {
  background-color: $yellow;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 400px auto;
  margin: ($gutter*3) 0;
  padding-bottom: ($gutter);
  padding-top: ($gutter);

  @include media-breakpoint-down(sm) {
    background-image: none !important;
    margin: ($gutter*2) 0;
  }

  @include media-breakpoint-up(md) {
    background-size: 300px auto;
  }

  @include media-breakpoint-up(lg) {
    background-size: 400px auto;
  }
}

.gallery-item {
  padding-top: 0;

  &:nth-child(1) figure {
    padding-top: 0;

    @include media-breakpoint-up(md) {
      padding-top: $gutter*4;
    }
  }

  &:nth-child(2) figure {
    padding-top: 0;

    @include media-breakpoint-up(md) {
      padding-top: $gutter*8;
    }
  }

  &:nth-child(3) figure {
    padding-top: 0;

    @include media-breakpoint-up(md) {
      padding-top: $gutter*2;
    }
  }

  &:nth-child(4) figure {
    padding-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: -($gutter*2);
    }
  }

  &:nth-child(5) figure {
    padding-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: ($gutter*2);
    }
  }
}

.full--img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 600px;
  }
}

.management .slice-section { padding-top: $gutter; }

.management .slice-section hr { display: none; }

.full--details,
.full--width {

  ul {
    border-bottom: 1px solid $blue;
    display: block;
    list-style: none;
    margin: 0 0 ($gutter);
    padding: 0 0 ($gutter/2);
    width: 100%;

    li {
      @include font-size(14px);
      display: block;
      padding-bottom: $gutter/2;
      padding-right: 0;

      @include media-breakpoint-up(md) {
        @include font-size(16px);
        display: inline-block;
        padding-bottom: 0;
        padding-right: $gutter*2;
      }
    }

    @include media-breakpoint-up(md) {
      margin: 0 0 $gutter*1.5;
      padding: 0 0 $gutter*1.5;
    }
  }

  a {
    text-decoration: underline;
  }
}

.full--width {
  border-bottom: 1px solid $blue;
  width: 100%;

  ul {
    border: none;
    margin-bottom: 0;
    padding-top: $gutter/2;

    @include media-breakpoint-up(md) {
      padding-top: $gutter*1.5;
    }
  }
}

a.link--article {
  border-bottom: 1px solid $blue;
  display: block;
  @include font-size(18px);
  letter-spacing: 0;
  line-height: 1;
  line-height: 26px;
  padding: ($gutter/2) $gutter ($gutter/2) 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  @include media-breakpoint-up(md) {
    @include font-size(36px);
    line-height: 48px;
    padding: ($gutter*2) ($gutter*6) ($gutter*2) 0;
  }
}

.latest--news .row:last-child a.link--article {
  border-bottom: none;
}

a.learn--more {
  text-decoration: underline;
  text-transform: uppercase;

  &:after {
    border: solid $blue;
    border-width: 0 2px 2px 0;
    content: '';
    display: inline-block;
    margin-left: $gutter/5;
    margin-top: -2px;
    padding: 2px;
    position: relative;
    top: -2px;
    transform: rotate(-45deg) translateX(0px);
    -webkit-transform: rotate(-45deg) translateX(0px);
  }

  &:hover {
    text-decoration: none;
  }
}

// Well Connected
.get-around {

  @include media-breakpoint-down(sm) {
    background: none !important;
  }

  @include media-breakpoint-up(md) {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 300px auto;
    border-bottom: 1px solid $blue;
    margin-bottom: $gutter;
    padding-bottom: $gutter*3;
  }

  @include media-breakpoint-up(lg) {
    background-size: 400px auto;
  }

  ul {
    list-style: none;
    margin: 0 0 3rem;
    padding: 0;

    li {
      @extend .align-middle;
      background-position: left;
      background-repeat: no-repeat;
      background-size: 35px auto;
      display: block;
      @include font-size(14px);
      padding-left: $gutter*1.5;
      padding-bottom: $gutter/2;
      padding-top: $gutter/2;

      &:nth-child(1) {
        background-image: url('./images/icon-travel-ferry.svg');
      }

      &:nth-child(2) {
        background-image: url('./images/icon-travel-808.svg');
      }

      &:nth-child(3) {
        background-image: url('./images/icon-travel-plane.svg');
      }

      &:nth-child(4) {
        background-image: url('./images/icon-travel-bart.svg');
        background-size: 20px;
      }

      @include media-breakpoint-up(lg) {
        @include font-size(26px);
        background-size: 70px auto;
        padding-left: $gutter*3;
        padding-bottom: $gutter;
        padding-top: $gutter;

        &:nth-child(4) {
          background-size: 40px;
        }
      }
    }

  }

}

.travel .my-5 {
  position: relative;
  margin-bottom: $gutter*4 !important;

  &.last { margin-bottom: $gutter*2 !important; }

  @include media-breakpoint-up(md) {
    margin-bottom: $gutter*6 !important;
  }
}

.travel {
  border-bottom: 1px solid $blue;
  //margin-bottom: $gutter*3;

  @include media-breakpoint-up(md) {
    margin-bottom: $gutter*3;
  }

  h5 {
    @extend .pb-5;
  }

  .text-white a {
    color: $white;
    display: inline-block;
    @extend .pt-5;
    text-decoration: underline;
  }

  .color--bg {
    height: 95%;
    left: 0;
    position: absolute;
    top: -8%;
    width: 100%;

    @include media-breakpoint-up(md) {
      bottom: 0;
      height: 120%;
      left: auto;
      position: absolute;
      right: 15px;
      top: -10%;
      width: 82%;
    }

    &.color--bg-blue {
      background-color: $blue;
    }

    &.color--bg-peach {
      background-color: $peach;

      @include media-breakpoint-up(md) {
        left: 15px;
        right: auto;
      }
    }

    &.color--bg-yellow {
      background-color: $yellow;
    }

  }

}

.commute--data {
  background-color: $blue;
  color: $white;

  a { color: $white; display: inline-block; }
}

// business
.block--text {
  padding-bottom: $gutter*3;
  padding-top: $gutter*3;
}

.pullquote {
  //padding-bottom: $gutter*3;
  //padding-top: $gutter*3;

  h3 {
    @include font-size(26px);
    text-transform: none;

    @include media-breakpoint-up(md) {
      @include font-size(52px);
      text-transform: none;
    }
  }

  h3 { margin-bottom: 0; padding-bottom: 0 !important; }

  .mint,
  .peach,
  .blue {
    padding-bottom: $gutter*3;
    padding-top: $gutter*3;
    width: 100%;
  }

  .mint {  background-color: $mint; }
  .peach { background-color: $peach; }
  .blue { background-color: $blue; color: $white; }
}

// tenants
hr {
  border-color: $blue;
  margin-bottom: $gutter;
  margin-top: 0;

  @include media-breakpoint-up(md) {
    margin-bottom: $gutter*3;
  }
}

.slice-section {
  padding-bottom: $gutter;
  padding-top: $gutter;

  h3 {
    @extend .pb-3;
    @extend .pb-md-5;
  }

  h4 {
    @extend .pb-3;
    @extend .pb-md-5;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: $gutter*3;
    padding-top: $gutter*3;
  }
}

.tenants {

  .row {
    border-bottom: 1px solid $blue;
    padding-bottom: $gutter;
    padding-top: $gutter;

    &:last-child { border: none; }
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.service-list {

  .row {
    border-bottom: 1px solid $blue;

    &:last-child { border: none; }
  }
}

.two-col-text {
  background-color: $yellow;
  padding-bottom: $gutter*2;
  padding-top: $gutter*2;

  .barrier {
    background-color: $blue;
    display: inline-block;
    height: 1px;
    margin: $gutter auto;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 90%;
      margin: 0 auto;
      width: 1px;
    }
  }

  h3 {
    @extend .pb-3;
    @extend .pb-md-5;
  }

  h4 {
    @extend .pb-3;
    @extend .pb-md-5
  }
}

// carousel
.carousel {
  padding-bottom: $gutter*3;
}
.carousel-indicators {
  bottom: 20px;

  li {
    background-color: $white;
    border: 2px solid $blue;
    border-radius: 50%;
    height: 14px;
    margin-left: $gutter/2;
    margin-right: $gutter/2;
    opacity: 1;
    width: 15px;

    &.active {
      background-color: $blue;
    }
  }
}

// Page Transitions
//
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  transition: opacity 300ms linear;
    -moz-transition: opacity 300ms linear;
    -webkit-transition: opacity 300ms linear;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
    -moz-transition: opacity 300ms linear;
    -webkit-transition: opacity 300ms linear;
}

.fade-exit {
  opacity: 1;

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
    -moz-transition: opacity 150ms linear;
    -webkit-transition: opacity 150ms linear;
  }
}
