// navs
.nav-item {
  @extend .lars-light;
  @include font-size(16px);
  line-height: 22px;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    @include font-size(22px);
    line-height: 36px;
  }
}

.dropdown-toggle {
  position: relative;
  z-index: 1001;
}

// dropdown
.dropdown-menu {
  background-color: $white;
  border-color: $white;
  border-radius: 0 !important;
  box-shadow: none;
  width: 100%;

  .dropdown-item {
    color: $blue;
    @include font-size(16px);
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: none;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(md) {
    border-color: $blue;
    border-radius: 2px !important;
    padding-top: $gutter*2;
    top: -60px !important;
  }

  .connected & {
    background-color: $yellow;
    border-color: $yellow;

    @include media-breakpoint-up(md) {
      border-color: $blue;
    }
  }
}

// mobile nav
#navSlider {
  background-color: white;
  border-top: 1px solid $white;
  //height: 0;
  margin-top: $gutter/2;
  overflow: hidden;
  width: 100%;
  z-index: 99999;

  .nav-item {
    border-top: 1px solid $blue;
    width: 100%;

    &:first-child { border: none; }

    .nav-link {
      padding: ($gutter/1.5) ($gutter/2);
    }

    &.dropdown {
      border-top: 1px solid $blue;
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
  }

  .dropdown-item {
    padding: $gutter/2;
  }

  &.show {
    border-color: $blue;
    height: 1200px;
  }

  .connected & { background-color: $yellow; }
}

body.nav-slider--open { overflow: hidden; }
