//
// Footer
//

#footer {
  background: $blue url('./images/bg-footer.png') 225% 125% no-repeat;
  background-size: 300px auto;
  color: $white;
  padding: 0 0 ($gutter*3.5);

  @include media-breakpoint-up(md) {
    background: $blue url('./images/bg-footer.png') 110% 230% no-repeat;
    background-size: 450px auto;
  }

  #directory {
    background-color: $white;
    border-top: 1px solid $blue;
    color: $blue;

    a { color: $blue; }

    .homepage &,
    .page & {
      display: none;
    }
  }

  #callout {
    background-color: $peach;
    color: $blue;
    margin: 0 0 ($gutter*2);
    padding: ($gutter*2) 0;

    h4 {
      text-transform: uppercase;
    }

    @include media-breakpoint-up(md) {
      margin: 0 0 ($gutter*3);
      padding: ($gutter*3) 0;
    }
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 0;
  }
}
