//
// Buttons
//

.btn--arrow {
  background: transparent url('./images/icon-arrow-lg.svg') 0 0 no-repeat;
  background-size: 50px auto;
  display: block;
  height: 35px;
  text-indent: -9999em !important;
  transform: translateX(0px);
  transition: transform .25s linear;
  width: 50px;

  &.btn--arrow-sm {
    background-size: 50px auto;
    height: 35px;
    width: 50px;
  }

  &:hover {
    transform: translateX(10px);
  }

  @include media-breakpoint-up(md) {
    background-size: 100px auto;
    height: 71px;
    width: 100px;
  }
}

button.nav-link {
  @extend .nav-item;
  border: none;
  color: $blue;
  background-color: transparent;

  &:focus { outline: none; }
}

.dropdown-toggle::after {
  border: solid $blue;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-left: $gutter/3;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.btn-hamburger {
  appearance: none;
  background: transparent url('./images/btn-nav-hamburger.png') 0 0 no-repeat;
  background-size: 33px auto;
  border: none;
  height: 33px;
  padding: 0;
  margin: 0;
  text-indent: -9999em;
  width: 33px;

  &[aria-expanded="true"] {
    background-image: url('./images/btn-nav-close.png');
  }
}
