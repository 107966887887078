//
// Reusable variables
//

$gutter: 30px;

// mixins
// set base body font size
@function calculate-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin font-size($size) {
  font-size: $size; //Fallback in px
  font-size: calculate-rem($size);
}
