//
// Typography
//

body {
  @extend .lars-reg;
}

h1,h2,h3,h4,h5,h6 {
  @extend .lars-light;
  text-transform: uppercase;
}

h1 {
  @include font-size(30px);
  letter-spacing: 0.33px;
  line-height: 40px;

  @include media-breakpoint-up(md) {
    @include font-size(48px);
    letter-spacing: 0;
    line-height: 52px;
  }

  @include media-breakpoint-up(lg) {
    @include font-size(110px);
    letter-spacing: 1px;
    line-height: 116px;
  }

  &.business--name {
    @extend .lars-bold;
    @include font-size(10px);
    letter-spacing: 1px;

    @include media-breakpoint-up(md) {
      @include font-size(14px);

      &:before {
        border: 2px solid $blue;
        border-radius: 5px;
        content: "";
        display: inline-block;
        height: 10px;
        margin-right: $gutter/3;
        width: 10px;
      }
    }
  }
}

h2 {
  @include font-size(36px);
  letter-spacing: 0.33px;
  line-height: 40px;

  @include media-breakpoint-up(md) {
    @include font-size(48px);
    letter-spacing: 0;
    line-height: 52px;
  }

  @include media-breakpoint-up(lg) {
    @include font-size(80px);
    letter-spacing: 0.73px;
    line-height: 110px;
  }
}

h3 {
  @include font-size(30px);
  letter-spacing: 0.27px;
  line-height: 36px;

  @include media-breakpoint-up(md) {
    @include font-size(48px);
    letter-spacing: 0;
    line-height: 52px;
  }

  @include media-breakpoint-up(lg) {
    @include font-size(66px);
    letter-spacing: 1px;
    line-height: 72px;
  }
}

h4 {
  @extend .lars-reg;
  @include font-size(22px);
  letter-spacing: 0;
  line-height: 26px;
  text-transform: none;

  @include media-breakpoint-up(md) {
    @include font-size(36px);
    letter-spacing: 0;
    line-height: 42px;
  }

  @include media-breakpoint-up(lg) {
    @include font-size(48px);
    letter-spacing: 0;
    line-height: 52px;
  }
}

h5 {
  @include font-size(26px);
  letter-spacing: 0.39px;
  line-height: 30px;

  @include media-breakpoint-up(md) {
    @include font-size(36px);
    letter-spacing: 0;
    line-height: 42px;
  }
}

h6 {
  @extend .lars-reg;
  @include font-size(18px);
  letter-spacing: 0px;
  line-height: 30px;

  @include media-breakpoint-up(md) {
    @include font-size(22px);
    letter-spacing: 0;
    line-height: 36px;
  }
}

p {
  @include font-size(16px);
  line-height: 22px;

  @include media-breakpoint-up(md) {
    @include font-size(22px);
    line-height: 36px;
    margin-bottom: $gutter;
  }
}
