//
// Colors
//

$black: rgb(0,0,0);
$white: rgb(255,255,255);

$blue: rgb(35,87,255);
$peach: rgb(255,225,199);
$yellow: rgb(255,254,205);
$mint: rgb(206,252,228);

$btMint: rgb(148,232,210);
$green: rgb(140,160,35);
